import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import OutboundLink from '../../../components/OutboundLink';
import Video from '../../../components/Video';

const HowWeAreAffectedByPreference = () => (
  <Layout title="How We Are Affected by Preference">
    {/* BREADCRUMBS */}
    <Section className="breadcrumbs-section">
      <Container>
        <Row>
          <Column size={10}>
            <ul className="breadcrumbs">
              <li className="breadcrumbs__item active">
                <Link to="/behavioral-economics">Behavioral Economics</Link>
              </li>
              <li className="breadcrumbs__item">Video Topic Series</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO GALLERY PLAYER */}
    <Section className="video-player">
      <Container>
        <Row>
          <Column size={12}>
            <h2 className="page-subtitle">Video Topic Series</h2>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              guidSrc="2835f0e1-8f10-4b70-9240-090b0d25b00b"
              title="How We Are Affected by Preference"
            />
          </Column>
          <Column size={4}>
            <div className="thumbnails">
              <Link to="../introduction-to-behavioral-economics">
                <div className="thumbnails__row">
                  <div className="thumbnail">
                    <Image
                      filename="img-be-lesson-card-1.jpg"
                      className="thumbnail__image"
                      alt="Financial Markets Hero Image"
                    />
                  </div>
                  <div className="thumbnails__content">
                    <h4>Introduction to Behavioral Economics</h4>
                    <span className="thumbnails__duration">2:38min </span>
                    <p className="thumbnails__desription">
                      Dive deeper into students’ everyday thought processes...
                    </p>
                  </div>
                </div>
              </Link>
              <div className="thumbnails__row active">
                <div className="thumbnail">
                  <Image
                    filename="img-be-lesson-card-2.jpg"
                    className="thumbnail__image"
                    alt="Financial Markets Hero Image"
                  />
                </div>
                <div className="thumbnails__content">
                  <h4>How We Are Affected by Preference</h4>
                  <span className="thumbnails__duration">2:46min </span>
                  <p className="thumbnails__desription">
                    Find out how much preference influences people’s final...
                  </p>
                </div>
              </div>
              <Link to="../how-we-are-affected-by-others">
                <div className="thumbnails__row">
                  <div className="thumbnail">
                    <Image
                      filename="img-be-lesson-card-3.jpg"
                      className="thumbnail__image"
                      alt="Financial Markets Hero Image"
                    />
                  </div>
                  <div className="thumbnails__content">
                    <h4>How We Are Affected by Others</h4>
                    <span className="thumbnails__duration">2:15min </span>
                    <p className="thumbnails__desription">
                      Discover the ways that outside influences can "nudge"...
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="../how-we-are-affected-by-price">
                <div className="thumbnails__row">
                  <div className="thumbnail">
                    <Image
                      filename="img-be-lesson-card-4.jpg"
                      className="thumbnail__image"
                      alt="Financial Markets Hero Image"
                    />
                  </div>
                  <div className="thumbnails__content">
                    <h4>How We Are Affected by Price</h4>
                    <span className="thumbnails__duration">2:04min </span>
                    <p className="thumbnails__desription">
                      Take a closer look at everyday buying habits as
                      students...
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO DETAIL INFO */}
    <Section className="video-resources">
      <Container>
        <Row>
          <Column size={8} className="video-resources__left">
            <h1 className="page-title">How We Are Affected by Preference</h1>
            <p>
              Find out how much preference influences people’s final decisions
              with a hands-on lesson exploring the way financial losses and
              gains impact human behavior.
            </p>
            <div className="supporting-resources box-shadow br-all">
              <h3>Supporting Resources</h3>
              <p>
                Learn how you can incorporate thought-provoking Behavioral
                Economics topics into your lessons with a comprehensive educator
                guide, classroom slideshow and challenge activity.
              </p>
              <div className="asset__files card">
                <OutboundLink
                  to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-2-HowWeAreAffectedByPreference.pdf"
                  target="_blank"
                  className="asset__file pdf"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
                <OutboundLink
                  to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-PPT-2-HowWeAreAffectedByPreference.pptx"
                  target="_blank"
                  className="asset__file ppt"
                >
                  <Icon name="download" />
                  Classroom Slideshow (.ppt)
                </OutboundLink>
                <OutboundLink
                  to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-2-ChallengeActivity.pdf"
                  target="_blank"
                  className="asset__file pdf"
                >
                  <Icon name="download" />
                  Challenge Activity (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="video-resources__right box-shadow br-all">
              <h3>About the Video Topic Series</h3>
              <p>
                <span className="bold block">
                  Do humans defy logic and reason when they make financial
                  decisions?
                </span>
                Explore the ways psychology informs economic decision-making
                with this four-part Behavioral Economics Digital Lesson Series.
                Reframe core economics concepts in exciting new ways with a
                series of turnkey lesson resources including video, student
                activity, educator guide and more.
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP | 
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default HowWeAreAffectedByPreference;
